import { useEffect, useState } from "react";
import logo from "../assets/images/logo.png";
import TopBottom from "./TopBottom";

const Topbar = () => {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(true);

  const controlNavbar = () => {
    if (window.scrollY > 180) {
      // if scroll down hide the navbar
      setShow(false);
    } else {
      // if scroll up show the navbar
      setShow(true);
    }
  };

  const resList = ["Dominos", "Dominos", "Dominos"];
  const links = [
    { name: "Home", url: "home" },
    { name: "Category", url: "category" },
    { name: "Features", url: "features" },
    { name: "Review", url: "review" },
    { name: "Gallery", url: "gallary" },
    { name: "FAQs", url: "faqs" },

    // { name: "Register", url: "register" },
  ];

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);
  //   console.log("isFixed", show);

  return (
    <nav className="topbar">
      <div className="topbar__top">
        <div className="topbar__top__logo">
          <img src={logo} alt="logo" />
        </div>

        <div className="topbar__top__right">
          <div className="topbar__top__call">
            <i class="icon-call-out"></i>
            <div className="topbar__top__call__left">
              <p>need help?</p>
              <a href="tel:+2349064812764">0815 5832 950</a>
            </div>
          </div>
          <div className="topbar__top__search">
            <input type="text" />
            <i class="icon-magnifier"></i>
          </div>
        </div>
      </div>

      {show && (
        <TopBottom
          alt={true}
          setOpen={setOpen}
          resList={resList}
          links={links}
          open={open}
          show={show}
        />
      )}

      <TopBottom
        setOpen={setOpen}
        resList={resList}
        links={links}
        open={open}
        show={show}
      />
    </nav>
  );
};

export default Topbar;
