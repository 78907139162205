import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  const links = [
    { name: "Home", url: "home" },
    { name: "Category", url: "category" },
    { name: "Features", url: "features" },
    { name: "Gallary", url: "gallary" },
    // { name: "FAQs", url: "faqs" },
  ];
  const linksAlt = [
    { name: "Review", url: "review" },
    { name: "FAQs", url: "faqs" },
    { name: "Terms & Conditions", url: "terms" },
    { name: "Privacy Policy", url: "privacy" },
  ];
  return (
    <div className="footer">
      <div className="footer__main">
        <div className="footer__main__logo">
          <img src={logo} alt="logo" />
          <p>Bringing the store to your door step</p>
          <ul className="footer__main__logo__links">
            <li>
              <a
                href="https://www.facebook.com/orderfoodapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="icon-social-facebook"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCB5MW3VFYLETNI__GJP4Sbg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="icon-social-youtube"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/orderfoodapp/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="icon-social-instagram"></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="footer__main__item">
          <h4>Contact Info</h4>
          <div className="footer__main__item__info">
            <i className="icon-home"></i>
            Plot 19, Wole Soyinka Ave, 2nd Ave, Gwarinpa
          </div>
          <div className="footer__main__item__info">
            <i className="icon-envelope"></i>
            <a href="mailto:info@orderfood.ng">Email: info@orderfood.ng</a>
          </div>
          <div className="footer__main__item__info">
            <i className="icon-call-in"></i>
            <a href="tel:+2349064812764">09064812764</a>
          </div>
        </div>

        <div className="footer__main__item">
          <h4>Links</h4>
          <ul>
            {links.map((item) => {
              return (
                <HashLink key={item.name} to={`/#${item.url}`}>
                  <li key={item.name}>{item.name}</li>
                </HashLink>
              );
            })}
          </ul>
        </div>
        <div className="footer__main__item">
          <h4>Company</h4>
          <ul>
            {linksAlt.map((item, i) => {
              return i > 1 ? (
                <Link key={item.name} to={"/" + item.url}>
                  <li>{item.name}</li>{" "}
                </Link>
              ) : (
                <HashLink key={item.name} to={`/#${item.url}`}>
                  <li>{item.name}</li>{" "}
                </HashLink>
              );
            })}
          </ul>
        </div>
      </div>

      <div className="footer__bottom">
        <div>
          Copyright © 2024 <span>orderfood</span>. All Rights Reserved.
        </div>
        <div>
          powered by:{" "}
          <a href="https://lilysolutions.org/">
            <b>LilySolutions Limited</b>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
