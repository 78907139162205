import React, { useEffect } from "react";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div class="terms">
      <h1>GENERAL TERMS AND CONDITIONS </h1>
      <br />
      <h2>Interpretation and introduction </h2>
      <p>
        <span class="numbers">1.0</span> In these General Terms and Conditions,
        the definitions contained in the Key Commercial Terms, together with the
        following further definitions, will apply:
        <br />
        <br />
        “Agreement” means the Form of Agreement, the Key Commercial Terms, these
        General Terms and Conditions, and the Orderfood codes, policies and
        guidelines referenced herein
        <br />
        <br />
        "Content" means all works and materials (including without limitation
        text, graphics, images, audio material, video material, audio-visual
        material, scripts, software and files) that the Seller submits to
        Orderfood platform for publication pursuant to this Agreement;
        <br />
        <br />
        “Customers” means purchasers of the Products on the Platform;
        <br />
        <br />
        “Failed Delivery” means:
        <br />
        <br />
        in respect of the Full Service Delivery Model, failure to deliver an
        order to a Customer (including late delivery resulting in a canceled
        order) provided that the order was complete, properly packaged and ready
        within the Order Preparation Time; and in respect of the Forwarding
        Model, failure to deliver an order to a Customer provided that Orderfood
        platform provided accurate order and delivery information to the Seller;
        <br />
        <br />
        “Full Service Delivery Model” means that Orderfood platform shall be
        responsible for collecting orders from the Business Premises and
        delivering to Customers;
        <br />
        <br />
        “Forwarding Model” means that Orderfood platform shall be responsible
        for forwarding orders to the Seller for delivery by the Seller to the
        Customer;
        <br />
        <br />
        “Platform” means Orderfood Meal, Orderfood Drink, Orderfood Grocery and
        any other on demand services available from Orderfood platform;
        <br />
        <br />
        “Products” means the Seller’s products listed on the Platform (which
        shall in respect of Orderfood app Food mean the restaurant’s menu
        items);
        <br />
        <br />
        “Order Processing Time” means the maximum period of time from
        notification of an order for acceptance of that order, as specified in
        the Vendor Application;
        <br />
        <br />
        “Order Preparation Time” means the maximum period of time from
        acceptance of an order to readiness for collection, as specified in the
        Vendor Application.
        <br />
        <br />
        “Parties” or “Party” means Orderfood app and the Seller, as each defined
        in the Key Commercial Terms, and as the context requires;
        <br />
        <br />
        “Transaction” means the purchase of Products by a Customer on the
        Platform; “Vendor Application” means the Android application through
        which the Seller may view its account;
        <br />
        <br />
        "we" means Orderfood tech company, as defined in the Key Commercial
        Terms (and "us" and "our" should be construed accordingly); and
        <br />
        <br />
        "you" means the Seller as defined in the Key Commercial Terms (and
        "your" should be construed accordingly).
        <br />
      </p>
      <p>
        1.2. In this Agreement use of the singular may include the plural and
        vice versa, as the context requires.
      </p>
      <p>
        1.3. This Agreement shall govern the marketing, sale and delivery of the
        Products on the Platform.
      </p>
      <p>
        <h5>2. Registration and account</h5>
        <br />
        2.1. In order to be a User of the APP, it is essential that you meet the
        following requirements:
        <br />
        <br />
        2.1.1 Users must be at least 18 years of age.
        <br />
        <br />
        2.1.2 Users must truthfully complete the mandatory fields of the
        registration form requesting personal details such as the User’s name,
        e-mail address, telephone number and bank card number.
        <br />
        <br />
        2.1.3. Users must carefully read these General Terms when accessing the
        APP. Further, acceptance of the General Terms is an essential step to be
        taken before registration.
        <br />
        <br />
        2.1.4 Users must agree to the Privacy and Data Protection Policy.
        <br />
        <br />
        2.1.5. Users must agree to the Cookie Policy.
        <br />
        <br />
        2.1.5 Users must agree to the Standards of Ethics and Business Conduct
        for Third Parties available at https://www.orderfood.ng
        <br />
        <br />
        Once all requirements are fulfilled and registration on the APP is
        completed, orderfood tech will provide the User with the registration
        confirmation, together with any other information necessary in
        accordance with the law applicable. All Users who may be classified
        under the applicable law as “consumers” benefit and are protected by all
        relevant consumer protection laws and regulations when using the APP.
        <br />
        <br />
        2.2. You shall notify Orderfood’s tech if you (or a person or entity
        related to you) opens a new account on any of our platforms.
        <br />
        <br />
        2.3. You may not register with our Platform as a Seller if you are an
        employee of Orderfood’s tech or a family member of an employee of
        Orderfood’s tech unless Orderfood’s tech has approved the registration
        pursuant to its conflict of interest procedures.
        <br />
        <br />
        2.4. Following your registration as a vendor, we will create your
        account which you may view via the Vendor Application. You agree to:
        <br />
        <br />
        2.4.1. keep your password confidential;
        <br />
        <br />
        2.4.2. notify us in writing immediately if you become aware of any
        disclosure of your password; and
        <br />
        <br />
        2.4.3. be responsible for any activity on your account arising out of
        any failure to keep your password confidential, and that you shall be
        liable for any losses arising out of such a failure.
        <br />
        <br />
        2.6. Your account shall be used exclusively by you and you shall not
        transfer your account to any third party. If you authorize any third
        party to manage your account on your behalf this shall be at your own
        risk.
        <br />
      </p>
      <h5>3. Your Listing</h5>
      <p>
        3.1. The only Products that may be the subject of a listing on our
        Platform are products falling within the product categories specified in
        respect of that Platform.
        <br />
        <br />
        3.2. You shall be solely responsible for providing to order food tech
        the information required for listing your Products on the Platform which
        shall include (without limitation): a description of the Products;
        business hours; and prices. You shall provide Order food tech with at
        least two (2) days’ written notice of any changes to the information
        provided.
        <br />
        <br />
        3.3. Content and information submitted shall be reviewed and approved by
        Order food tech, in its discretion, before it is published on the
        Platform.
        <br />
        <br />
        3.4. You shall ensure that all Products listed are available/ in stock
        during your business hours, and that you operate sufficient capacity,
        including sufficient staff and equipment, to process all orders received
        through the Platform, within the Order Preparation Time.
        <br />
      </p>
      <h5>4. Pricing</h5>
      <br />
      <p>
        4.1. You shall be solely responsible for setting the price of your
        Products and related fees on the Platform. You may amend your prices
        from time to time, including for the purpose of seasonal or other
        discounts. We may implement price changes on your behalf if you provide
        us adequate notice in writing.
        <br />
        <br />
        4.2. Notwithstanding section 4.1 above you shall not make any Products
        available to Customers on the Platform at a higher price than the price
        that you would charge for the same either to Customers purchasing
        Products on your business Premises, by telephone orders, or on any
        third-party platform.
        <br />
        <br />
        Any promotional price discounts offered to Customers purchasing Products
        on your Business Premises, by telephone orders, or on any third-party
        platform shall also be offered to Customers on the Platform.
        <br />
      </p>
      <h5>5. Marketing and sales services</h5>
      <br />
      <p>
        We shall use reasonable endeavors to market and promote sales of the
        Products on the Platform. Without prejudice to your rights to set the
        price of your Products pursuant to section 5.1 above, Orderfood tech may
        from time to time undertake marketing and promotional activities
        including offering discounts in respect of the Products either:
        <br />
        <br />
        5.2.1. in collaboration with you on terms to be mutually agreed; or
        <br />
        <br />
        5.2.2. in our discretion, at our own cost, for the purpose of increasing
        sales and recruiting new Customers to the Platform.
        <br />
        <br />
        Our liability to you in respect of all services provided pursuant to
        this section 5 shall be subject to the limitations and exclusions on
        liability provided at section 18.
        <br />
      </p>
      <h5>6. Orders</h5>
      <br />
      <p>
        You shall purchase from us the Automated Ordering Device(s) and any
        replacement devices at the agreed Fee per Device, for the purpose of
        updating availability of Products, receiving orders and reviewing
        operational reports.
        <br />
        <br />
        We shall receive orders from Customers via the Platform on your behalf
        and shall transmit the orders to you via the Automated Ordering Device.
        <br />
        <br />
        You shall:
        <br />
        <br />
        6.3.1. keep the Automated Ordering Device(s) turned on and connected to
        a wireless network constantly during business hours.
        <br />
        <br />
        6.3.2. promptly confirm availability and accept orders within the Order
        Processing Time; and
        <br />
        <br />
        6.3.3. prepare and package all orders, together with a valid receipt,
        within the Order Preparation Time.
        <br />
        <br />
        6.4. If an order cannot be fulfilled, or cannot be fulfilled within the
        Order Preparation Time, you shall notify us within the Order Processing
        Time, in order that we may notify the Customer.
        <br />
        <br />
        6.5. For the avoidance of any doubt, Orderfood tech shall not be under
        any obligation to accept any Customer orders, and may set an ordering
        and delivery radius, and decline any orders that are outside of such
        radius or that it believes are not legitimate orders.
        <br />
      </p>
      <h5>7. Fulfilment and delivery</h5>
      <br />
      <p>
        7.1. Under the Full-Service Delivery Model, we shall collect the ordered
        Products from your Business Premises and deliver to Customers. In the
        event of a Failed Delivery, we shall be liable to you in respect of lost
        revenues (being payments that we would have received from Customers less
        applicable deductions pursuant to section 10.2 below).
        <br />
        <br />
        7.2. Under the Forwarding Model, you shall deliver the ordered Products
        to Customers directly. In the event of a Failed Delivery, you shall
        remain liable to us in respect of the Commission.
        <br />
        <br />
        7.3. The Parties shall conduct a weekly reconciliation in respect of
        failed orders. Orderfood tech determination in respect of responsibility
        for failed orders shall be final.
        <br />
        <br />
        7.4. Unless stated otherwise in the Key Commercial Terms, Orderfood tech
        or the Seller, as may be applicable in respect of the Full Service
        Delivery Model or the Forwarding Model respectively, shall charge the
        Customer the Delivery Fee, which shall be published on the Platform from
        time to time.
        <br />
      </p>
      <h5> 8. Fees for the services</h5>
      <br />
      <p>
        8.1. You shall pay to Orderfood tech:
        <br />
        <br />
        8.1.1. the Commission in respect of the Transactions, as evidenced in
        Orderfood tech
        <br />
        <br />
        8.1.2. any further fees agreed in respect of value-added services
        rendered pursuant to this Agreement.
        <br />
        <br />
        8.2. In the event of a promotion funded by Orderfood tech, the
        Commission shall be calculated based on the price set by the Seller at
        the time of the order. We may vary Commissions and/or fees from time to
        time, on prior notice to you. This will not affect any liability to pay
        Commission that accrues before the new rates are posted or for services
        that have been previously paid.
        <br />
      </p>
      <h5>9. VAT and other taxes</h5>
      <br />
      <p>
        9.1. All amounts stated on the Platform are stated inclusive of VAT and
        any other taxes applicable to the Transaction.
        <br />
        <br />
        9.2. You shall be exclusively liable in respect of all taxes applicable
        to the Transactions with Customers on the Platform, including VAT, and
        shall therefore be responsible for the reporting, filing and payment of
        the same.
        <br />
        <br />
        9.3. Commissions shall be calculated as a percentage of the NET Product
        price exclusive of VAT and any other taxes charged to the buyer and as
        published on the Platform.
        <br />
        <br />
        9.4. The Commissions and all other fees payable pursuant to section 8.1
        above are net of any taxes. You shall therefore be liable in respect of
        VAT, and any and all other applicable taxes in respect of the
        Commissions and fees.
        <br />
        <br />
        9.5. We may make tax-related deductions to payments processed by us on
        your behalf and remit such deducted amounts to the relevant government
        or tax authority.
        <br />
        <br />
        9.6. Promptly following receipt of a written request from us, you shall
        supply to us any information and documentation that we may reasonably
        request in order to identify you or facilitate our compliance with our
        legal obligations and/or any inquiry from a regulatory authority
        relating to the taxation of payments made to us or processed by us. We
        may supply such information and/or documentation to relevant government
        and tax authorities.
        <br />
      </p>
      <h5>10. Revenue Collection and Remittances</h5>
      <br />
      <p>
        10.1. Subject to section 10.10 below, which provides cash collection by
        the Seller upon delivery to Customers under the Forwarding Model, the
        process for revenue collection by Orderfood tech and remittance to the
        Seller is set out at sections 10.2 to 10.9 below. 10.2. We shall collect
        payments from Customers in respect of all Transactions (for the price of
        the Products and the Delivery Fees), and we shall deduct from the same
        our Commissions (and in respect of the Full-Service Delivery Model only
        our Delivery Fees), together with any charges, taxes, refunds or any
        other amounts that you owe to us in respect of any business whatsoever.
        <br />
        <br />
        10.3. Following the end of each Billing Period, we shall provide you
        with a statement of account which shall include details of all revenues,
        deductions and remittances pursuant to section 10.2.
        <br />
        <br />
        10.4. We shall remit the proceeds of each Transaction, after all
        deductions pursuant to section 10.2 above, and remit the remaining funds
        to you using payment mechanism as we may notify to you from time to
        time. 10.5. Evidence of payment to your nominated account shall
        constitute conclusive evidence of payment and receipt.
        <br />
        <br />
        10.6. You shall be entirely responsible for ensuring that the account
        details that you provide to us in the registration form are accurate and
        up to date, and that the account is secure. We shall not be liable for
        any loss or damage to you that may result from fraud or error in respect
        of your account.
        <br />
        <br />
        10.7. Subject to the applicable laws of the Jurisdiction, we may at any
        time set off any amount that you owe to us against any amount that we
        owe to you, by sending you written notice of the set-off. Set-off may
        apply across your accounts, if you operate more than one account across
        any of our platforms. For the avoidance of any doubt, the statement of
        account shall serve as such notice of set-off.
        <br />
        <br />
        10.8. In the event that we hold insufficient funds on your behalf for
        payment of any amounts that you owe us (your account is in debit) you
        shall pay the amounts you owe to us by such payment method as we may
        request.
        <br />
        <br />
        10.9. We may delay or suspend payment to you in the event of
        investigation of a potential fraud or other breach of this Agreement.
        <br />
        <br />
        10.10. Under the Forwarding Model the Seller may be required to collect
        payment in respect of the full amount of the relevant Transaction in
        cash from the Customer. Orderfood tech shall invoice the Seller the
        Commissions at the end of each Billing Period, except where it is stated
        in the Key Commercial Terms that the Seller is directly responsible for
        order management, in which case the Seller shall remit the full amount
        of the Transaction (i.e. the price of the Products and the Delivery
        Fees) to Orderfood tech in full the next working day after cash
        collection. Orderfood tech may deactivate the cash on delivery option if
        the Seller breaches this section 10.10 or for any other reason, in
        Orderfood tech discretion.
        <br />
        <br />
        10.11. In the event that you dispute the statement of account you must
        report your claim to Orderfood tech within three (3) months of the date
        of the relevant Transaction, failing which such claim shall be deemed to
        be waived.
        <br />
      </p>
      <h5> 11. Customer service</h5>
      <br />
      <p>
        11.1. We shall be responsible for handling and liaising directly with
        Customers to resolve any issues relating to their use of the Platform.
        <br />
        <br />
        11.2. You shall promptly attend to our queries in relation to Customer
        requests, queries or disputes.
        <br />
      </p>
      <h5>12. Your Content</h5>
      <br />
      <p>
        12.1. You shall ensure that the information and Content provided to
        Orderfood tech is at all times accurate, current and identical to the
        information and Content published in your Business Premises or on your
        website.
        <br />
        <br />
        12.2. You warrant, represent and undertake to us that:
        <br />
        <br />
        12.2.1. you are either the owner or the authorized supplier of your
        Content and you have the right to supply your Content in accordance with
        the terms of the Agreement; and <br />
        <br />
        12.2.2. your Content, and the use of your Content by us in accordance
        with the Agreement will not infringe the copyright or other intellectual
        property rights of any person in any jurisdiction and under any
        applicable law.
        <br />
        <br />
        12.3. You grant to us a worldwide, irrevocable, non-exclusive,
        royalty-free license to use, reproduce, store, adapt, publish, translate
        and distribute your Content across our marketing channels and any
        existing or future media.
        <br />
        <br />
        12.4. You hereby waive all your moral rights in your Content to the
        maximum extent permitted by applicable law; and you warrant and
        represent that all other moral rights in your Content have been waived
        to the maximum extent permitted by applicable law.
      </p>
      <h5>13. Intellectual property</h5>
      <br />
      <p>
        The Parties acknowledge and agree that they each retain all rights,
        title and interest to their own intellectual property, including but not
        limited to all copyright, trademarks, patents, registered and industrial
        designs in their respective services and platforms and any and all
        enhancements, modifications, updates and upgrades thereto. 13.2. Each
        Party agrees that it will not do anything calculated or likely to damage
        the other Party’s intellectual property or its reputation.
      </p>
      <h5> 14. Confidentiality, data privacy and data security</h5>
      <br />
      <p>
        14.1. All information and documents concerning the conduct of business
        pursuant to the Agreement, including information relating to business
        methods, procedures, policies and sales information, is strictly
        confidential unless it is already in the public domain. Neither Party
        shall disclose the other’s confidential information for any purpose
        other than to perform its obligations under this Agreement and or
        without written consent from the other Party.
        <br />
        <br />
        14.2. Both Parties acknowledge and agree that Orderfood tech shall own
        all personal data that it collects and receives from the Customers.
        <br />
        <br />
        14.3. In the event that you receive any Customer data in the performance
        of this Agreement, you represent, warrant and undertake to us that:
        <br />
        <br />
        14.3.1. you shall not directly solicit or contact Customers for any
        purpose, including in respect of special offers or sale of other goods
        or services;
        <br />
        <br />
        14.3.2. you shall not process or transfer such personal data except as
        provided by the Privacy and Cookie Notice on the Platform and with the
        Customer’s prior consent; and 14.3.3. you shall use such personal data
        in compliance with applicable personal data protection laws, including
        but not limited to taking all reasonable measures to ensure and maintain
        the security of such personal data.
        <br />
        <br />
        14.4. Without prejudice to of any of our other rights under the
        Agreement, in the event that you breach any of the provisions of section
        14.3 above:
        <br />
        <br />
        14.4.1. you shall be directly responsible to Customers for your misuse
        of their personal data; and/or
        <br />
        <br />
        14.4.2. you shall indemnify us in respect of any damages, fines,
        penalties or other losses we may incur as a result of your handling of
        personal data obtained pursuant to the Agreement.
        <br />
      </p>
      <h5>15. Due diligence and audit rights</h5>
      <br />
      <p>
        15.1. We operate an anti-money laundering compliance program and reserve
        the right to perform due diligence checks on all sellers and customers
        on the Platform.
        <br />
        <br />
        15.2. You agree to provide to us all such information, documentation and
        access to your business premises as we may require:
        <br />
        <br />
        15.2.1. in order to verify your adherence to, and performance of, your
        obligations under the Agreement;
        <br />
        <br />
        15.2.2. for the purpose of disclosures pursuant to a valid order by a
        court or other governmental body; or
        <br />
        <br />
        15.2.3. as otherwise required by law or applicable regulation.
        <br />
      </p>
      <h5> 16. General warranties</h5>
      <br />
      <p>
        16.1. In addition to all other warranties and representations contained
        herein or implied by law, each Party hereby warrants and represents to
        the other Party that:
        <br />
        <br />
        16.1.1. it has the authority and the legal capacity, and any license(s)
        related thereto, to enter into the Agreement and fulfil its obligations,
        and is not under any disability, restriction or prohibition which
        prevents it from performing or adhering to any of its duties and
        obligations under the Agreement;
        <br />
        <br />
        16.1.2. it shall conduct its business relating to the subject matter of
        the Agreement in compliance with all applicable laws in force from time
        to time, including, without limitation: all applicable laws, statutes,
        regulations and other enactments of the Jurisdiction; the U.S. Foreign
        Corrupt Practices Act; the U.K. Bribery Act; and all other anti-bribery
        and corruption and anti-money laundering laws.
        <br />
        <br />
        16.1.3. it shall conduct its business relating to the subject matter of
        the Agreement: with skill and care, and by cooperating with and acting
        in good faith towards the Customers; in compliance with the Orderfood
        tech Partner Code of Conduct and other Orderfood tech codes, policies
        and guidelines published on the Platform from time to time; and in
        accordance with ethical business practices and industry best practice.
        <br />
        <br />
        16.2. You agree that you shall be responsible ensuring that the Products
        and the sale of the Products is in accordance with applicable laws,
        rules and regulations, including in respect of: the preparation,
        handling, storing, labeling and packaging of the Products; product
        and/or safety standards (including in respect of allergens, if
        applicable); and any registrations or licenses required for the sale of
        the Products.
        <br />
      </p>
      <h5> 17. Orderfood tech role as a marketplace</h5>
      <br />
      <p>
        17.1. You acknowledge that the Platform is a marketplace and we are not:
        <br />
        <br />
        17.1.1. party to the Transaction between you and the Customer, save that
        we provide a marketplace for you and the Customer and we may collect
        payments on your behalf; or
        <br />
        <br />
        17.1.2. agents for either you or the Customer, and accordingly we will
        not be liable to any person in relation to the offer for sale, sale or
        purchase of any Products.
        <br />
        <br />
        17.2. We do not warrant or represent that the Platform will operate
        without fault; or that the Platform or any service on the Platform will
        remain available.
        <br />
        <br />
        17.3. We reserve the right to discontinue or alter any or all of our
        marketplace services, and to stop publishing the Platform, at any time
        in our sole discretion without notice or explanation.
        <br />
        <br />
        17.4. We do not guarantee any commercial results concerning the use of
        the Platform.
        <br />
        <br />
        17.5. To the maximum extent permitted by applicable law and subject to
        section 18.1 below, we xclude all representations and warranties
        relating to the subject matter of the Agreement.
        <br />
      </p>
      <h5>18. Limitations and exclusions of liability</h5>
      <br />
      <p>
        Our aggregate liability to you in respect of the Agreement shall not
        exceed the total amount of fees and Commissions paid to us in the
        preceding twelve (12) month period. Notwithstanding section 18.1 above,
        we will not be liable to you in respect of:
        <br />
        <br />
        18.2.1. any losses arising out of any event or events beyond our
        reasonable control;
        <br />
        <br />
        18.2.2. any business losses, including (without limitation) loss of or
        damage to profits, income, revenue, use, production, anticipated
        savings, business, contracts, commercial opportunities or goodwill;
        <br />
        <br />
        18.2.3. any loss or corruption of any data, database or software; or
        <br />
        <br />
        18.2.4. any special, indirect or consequential loss or damage. You
        accept that we have an interest in limiting the personal liability of
        our officers and employees and, having regard to that interest, you
        acknowledge that we are a limited liability entity; you agree that you
        will not bring any claim personally against our officers or employees in
        respect of any losses you suffer in connection with the website or the
        Agreement (this will not limit or exclude the liability of the limited
        liability entity itself for the acts and omissions of our officers and
        employees). The limitations and exclusions of liability set out in this
        section 18 and elsewhere in these General Terms and Conditions:
        <br />
        <br />
        18.4.1. govern all liabilities arising under the Agreement or relating
        to the subject matter of the Agreement, including liabilities arising in
        contract, in tort (including negligence) and for breach of statutory
        duty, except to the extent expressly provided otherwise in these General
        Terms and Conditions; and <br />
        <br />
        18.4.2. not will limit or exclude any liabilities in any way that is not
        permitted under applicable law.
        <br />
      </p>
      <h5>19. Indemnification</h5>
      <br />
      <p>
        You hereby indemnify and hold harmless Orderfood tech, our officers,
        members, managers, employees, agents, contractors, sublicensees,
        affiliates, subsidiaries, successors, and assigns from and against:
        <br />
        <br />
        19.1.1. any and all losses with respect to any Customer or third party
        claim arising out of or related to any applicable retail food or other
        health and safety code, and
        <br />
        <br />
        19.1.2. any and all losses, damages, costs, liabilities and expenses
        (including without limitation legal expenses and any amounts paid to any
        third party in settlement of a claim or dispute) incurred or suffered
        and arising directly or indirectly out of your use of the Platform or
        any breach by you of any provision of the Agreement.
        <br />
        <br />
        19.2. You hereby acknowledge and agree that, pursuant to section 9.3 you
        shall be liable in respect of all taxes including value added tax in
        respect of all Transactions, and shall therefore be responsible for
        reporting, filing and payment of the same. Should a competent tax
        authority determine that Orderfood tech is liable for payment of any
        taxes in respect of the Transactions, you hereby indemnify and hold
        harmless Orderfood tech in respect of the same.
        <br />
        <br />
        19.3. It shall be your responsibility to maintain in force a policy of
        insurance in respect of your liabilities under the Agreement.
        <br />
      </p>
      <h5> 20. Consequences of breach</h5>
      <br />
      <p>
        20.1. We may take any of the measures specified in section 20.2 below
        if: 20.1.1. you breach the Agreement in any way, or if we reasonably
        suspect that you have breached the Agreement in any way; or
        <br />
        <br />
        20.1.2. you fail to satisfy a minimum level of operational performance
        in order to provide a satisfactory customer experience on the Platform.
        <br />
        <br />
        20.1.3. we receive negative complaints or reviews.
        <br />
        <br />
        20.2. In the event of the circumstances specified in section 20.1 above
        we may, without prejudice to our other rights:
        <br />
        <br />
        20.2.1. send you one or more formal warnings;
        <br />
        <br />
        20.2.2. temporarily suspend your access to the Platform;
        <br />
        <br />
        20.2.3. apply penalties (including financial penalties) for breach in
        accordance with any penalty policy that we may publish from time to
        time;
        <br />
        <br />
        20.2.4. terminate the Agreement pursuant to section 21 below and
        permanently remove your access to the Platform; and/or
        <br />
        <br />
        20.2.5. commence legal action against you, whether for breach of
        contract or otherwise.
      </p>
      <h5>21. Term and Termination</h5>
      <br />
      <p>
        21.1. The Agreement shall enter into force on the Effective Date and
        shall continue in force thereafter until terminated by either Party in
        accordance with section 21.2 or 21.3 below.
        <br />
        <br />
        21.2. Either Party may terminate the Agreement without cause by giving
        to the other Party thirty (30) days’ written notice of termination.
        <br />
        <br />
        21.3. Either Party may terminate the Agreement with immediate effect by
        giving written notice of termination to the other Party if:
        <br />
        <br />
        21.3.1. the other Party is in default or breach of any of the terms of
        the Agreement and such breach is not remedied within thirty (30) days of
        service of notice in writing; or
        <br />
        <br />
        21.3.2. a bankruptcy, insolvency, or winding-up order has been obtained
        against the other Party;
        <br />
        <br />
        21.3.3. the other Party enters into a scheme of compromise or
        arrangement with its creditors or enters into an assignment for the
        benefit of its creditors;
        <br />
        <br />
        21.3.4. the other Party ceases to conduct all (or substantially all) of
        its business; or
        <br />
        <br />
        21.3.5. the other Party is or becomes unable to pay its debts as they
        fall due.
        <br />
      </p>
      <h5>22. Effects of termination</h5>
      <br />
      <p>
        22.1. Upon termination of the Agreement we shall:
        <br />
        <br />
        22.1.1. permanently remove your access to the Platform; and <br />
        <br />
        22.1.2. issue to you a final statement of account in respect of all
        amounts due pursuant to the Agreement which shall be settled within
        thirty (30) days of termination.
        <br />
        <br />
        22.2. Upon the termination of the Agreement all of the provisions of the
        Agreement shall cease to have effect, save that any provision of the
        Agreement that expressly or by implication is intended to come into or
        continue in force on or after termination or expiry of the Agreement
        shall remain in full force and effect.
        <br />
        <br />
        22.3. Except to the extent that these General Terms and Conditions
        expressly provide otherwise, the termination of the Agreement shall not
        affect the accrued rights of either Party.
        <br />
      </p>
      <h5> 23. Subcontracting and assignment</h5>
      <br />
      <p>
        23.1. You hereby agree that we may assign, transfer, sub-contract or
        otherwise deal with our rights and/or obligations under the Agreement.
        <br />
        <br />
        23.2. You may not without our prior written consent assign, transfer,
        sub-contract or otherwise deal with any of your rights and/or
        obligations under the Agreement. 24
        <br />
      </p>
      <h5>24. Third party rights</h5>
      <br />
      <p>
        24.1. The Agreement is for our benefit and your benefit, and is not
        intended to benefit or be enforceable by any third party.
        <br />
        <br />
        24.2. The exercise of the Parties' rights under the Agreement is not
        subject to the consent of any third party.
        <br />
      </p>
      <h5> 25. Entire agreement</h5>
      <br />
      <p>
        25.1. The following Orderfood tech codes, policies and guidelines, as
        may be amended by Orderfood tech from time to time, form an integral
        part of the Agreement: (a) Privacy Policy; (b) Privacy and Cookie
        Notice; and (c) Partner Code of Conduct (current version as at the
        Effective Date published at{" "}
        <a href="https://investor.orderfood.ng/corporate-governanc">
          https://investor.orderfood.ng/corporate-governanc
        </a>
        e/#governance-documents).
        <br />
        <br />
        25.2. The Form of Agreement, Key Commercial Terms, these General Terms
        and Conditions, and the Orderfood tech codes, policies and guidelines
        shall constitute the entire agreement between you and us in relation to
        your use of the Platform and shall supersede all previous agreements
        between you and us in relation to your use of the Platform.
        <br />
      </p>
      <h5>26. Hierarchy</h5>
      <br />
      <p>
        26.1. Should the Form of Agreement, the Key Commercial Terms, these
        General Terms and Conditions, and the Orderfood tech codes, policies and
        guidelines be in conflict, the Form of Agreement, the Key Commercial
        Terms, these General Terms and Conditions, and the Orderfood tech codes,
        policies and guidelines shall prevail in the order here stated.
        <br />
      </p>
      <h5>27. No waiver or variation</h5>
      <br />
      <p>
        27.1. No breach of any provision of the Agreement will be waived except
        with the express written consent of the Party not in breach.
        <br />
        <br />
        27.2. No waiver of any breach of any provision of the Agreement shall be
        construed as a further or continuing waiver of any other breach of that
        provision or any breach of any other provision of the Agreement.
        <br />
        <br />
        27.3. No variation of the Agreement shall be valid unless it is in
        writing and refers specifically to the Agreement.
      </p>
      <br />
      <h5> 28. Severability</h5>
      <br />
      <p>
        28.1. If a provision of the Agreement is determined by any court or
        other competent authority to be unlawful and/or unenforceable, the other
        provisions will continue in effect. 28.2. If any unlawful and/or
        unenforceable provision of the Agreement would be lawful or enforceable
        if part of it were deleted, that part will be deemed to be deleted, and
        the rest of the provision will continue in effect.
        <br />
      </p>
      <p>
        <h5> 29. Notice</h5>
        <br />
        29.1. Any notice required pursuant to the Agreement or given in
        connection with the Agreement, shall be in writing and shall be given to
        the appropriate Party by personal delivery, by email, by internationally
        recognised courier services, or by certified mail postage prepaid, to
        the Seller Contact or the Orderfood tech Contact as applicable.
        <br />
        <br />
        29.2. Notices sent as above shall be deemed to have been received upon
        delivery in the case of personal delivery, next working day after
        sending by email, within 3 working days after sending by internationally
        recognised courier services, or within 7 working days after posting in
        the case of certified mail postage prepaid. saasdaz
        <br />
      </p>
      <p>
        <h5>30. Counterparts</h5>
        30.1. The Parties acknowledge and agree that this Agreement may be
        executed:
        <br />
        <br />
        30.1.1. in counterparts, all of which shall constitute a single
        agreement; and <br />
        <br />
        30.1.2. to the extent permitted by applicable law, by electronic
        signature, which shall be considered as an original signature <br />
        <br />
        30.1.3. for all purposes and shall have the same force and effect as an
        original signature.
        <br />
        <br />
        Law and jurisdiction The Agreement shall be governed by and construed in
        accordance with the laws of the
        <br />
        <br />
        Jurisdiction. Any disputes relating to the Agreement shall be subject to
        the exclusive jurisdiction of the courts of the Jurisdiction.
      </p>
    </div>
  );
};

export default Terms;
