import hero from "../assets/images/hero3.jpg";
import apple from "../assets/images/svg/apple.svg";
import play from "../assets/images/svg/play.svg";

const Hero = () => {
  return (
    <section id="home" className="hero">
      <div className="hero__main">
        <div className="hero__main">
          <h1>
            OrderFood Available On <br /> Play store & App store.
          </h1>
          {/* <h1>Order Food</h1> */}
          <p>
            OrderFood App is an online food delivery platform that connects
            consumers with their favourite local businesses in more than 2
            countries across the globe. Founded in January 2024, Orderfood
            builds services to help businesses innovate, grow, and reach more
            customers.
          </p>
          <div className="hero__main__btn">
            <button className="hero__main__btn__item">
              <img src={play} className="hero__main__btn__item__img" alt="" />
              <div className="hero__main__btn__item__right">
                DOWNLOAD ON <br />
                <span>Play Store</span>
              </div>
            </button>
            <button
              className="hero__main__btn__item"
              style={{ paddingLeft: 5, gap: 10 }}
            >
              <img
                src={apple}
                className="hero__main__btn__item__img alt"
                alt=""
              />
              <div className="hero__main__btn__item__right">
                DOWNLOAD ON <br />
                <span>App store</span>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="hero__img">
        <img src={hero} alt="" />
      </div>
    </section>
  );
};

export default Hero;
