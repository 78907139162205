import apple from "../assets/images/svg/apple.svg";
import play from "../assets/images/svg/play.svg";
import mobile1 from "../assets/images/mobile1.png";

const AppModal = ({ setModalIsOpen }) => {
  return (
    <div className="categories__modal">
      <div
        className="categories__modal__close"
        onClick={() => setModalIsOpen(false)}
      >
        <span>+</span>
      </div>
      <img className="categories__modal__img" src={mobile1} alt="" />

      <div className="hero__main__btn" style={{ justifyContent: "center" }}>
        <button className="hero__main__btn__item">
          <img src={play} className="hero__main__btn__item__img" alt="" />
          <div className="hero__main__btn__item__right">
            DOWNLOAD ON <br />
            <span>Play Store</span>
          </div>
        </button>
        <button
          className="hero__main__btn__item"
          style={{ paddingLeft: 5, gap: 10 }}
        >
          <img src={apple} className="hero__main__btn__item__img alt" alt="" />
          <div className="hero__main__btn__item__right">
            DOWNLOAD ON <br />
            <span>App store</span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default AppModal;
