import { useEffect } from "react";
import Categories from "../components/Categories";
import Faq from "../components/Faq";
import Feature from "../components/Feature";
import Flyers from "../components/Flyers";
import Hero from "../components/Hero";
import Partners from "../components/Partners";
import Review from "../components/Review";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="home">
      <Hero />
      <Flyers />
      <Categories />
      <Feature />
      <Review />
      <Partners />
      <Faq />
    </div>
  );
};

export default Home;
