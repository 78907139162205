import { useEffect } from "react";

const Policy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div class="privacy">
      <h1>Orderfood Tech Privacy Policy</h1>
      <h1>Index</h1>
      <p>
        <span class="number">1</span>. Scope
        <br />
        <span class="number">2.0</span> Summary of information
        <br />
        <span class="number">3.0</span> Your personal data rights
        <br />
        <span class="number">4.0</span> Data controller of your personal data
        <br />
        <span class="number">5.0</span> Useful information if you are a{" "}
        <span class="annotation">USER:</span>
        <br />
        <span class="number">5.1</span> What are the data processing purposes
        and the legitimate basis of
        <br />
        the processing?
        <br />
        <span class="number">5.2</span> What kind of data do we hold about you
        and how is your personal
        <br />
        data collected?
        <br />
        <span class="number">5.3</span> What are the recipients of your data and
        why are we communicating it?
        <br />
        <span class="number">5.4</span> How long will we keep your data?
        <br />
        ● Annex II- General retention periods and retention periods applicable
        for Users
        <br />
        <span class="number">6.0</span>Useful information if you are a{" "}
        <span class="annotation">COURIER:</span>
        <br />
        <span class="number">6.0</span> What are the data processing purposes
        and the legitimate basis of the processing?
        <br />
        <span class="number">6.2</span> What kind of data do we hold about you
        and how is your personal data collected?
        <br />
        <span class="number">6.3</span> What are the recipients of your data and
        why are we communicating it?
        <br />
        <span class="number">6.4</span>How long will we keep your data?
        <br />
        ● Annex II- General retention periods and retention periods applicable
        for Couriers
        <br />
        <span class="number">7.0</span>Useful information if you are a{" "}
        <span class="annotation">PARTNER:</span>
        <br />
        <span class="number">7.1</span> What are the data processing purposes
        and the legitimate basis of the processing?
        <br />
        <span class="number">7.2</span> What kind of data do we hold about you
        and how is your personal data collected?
        <br />
        <span class="number">7.3</span> How long will we keep your data?
        <br />
        ● Annex II- General retention periods and retention periods applicable
        to Partners
        <br />
        <span class="number">8.0</span> Useful information if you are an{" "}
        <span class="annotation">INVESTOR:</span>
        <br />
        <span class="number">8.1</span> What are the data processing purposes
        and the legitimate basis of the processing?
        <br />
        <span class="number">8.2</span> What kind of data do we hold about you
        and how is your personal data collected?
        <br />
        <span class="number">8.3</span>How long will we keep your data?
        <br />
        ● Annex II- General retention periods
        <br />
        <span class="number">9.0</span> Useful information if you are a{" "}
        <span class="annotation">JOB APPLICANT</span>
        <br />
        <span class="number">9.1</span> What are the data processing purposes
        and the legitimate basis of the processing?
        <br />
        <span class="number">9.2</span> What kind of data do we hold about you
        and how is your personal data collected?
        <br />
        <span class="number">9.3</span> How long will we keep your data?
        <br />
        ● Annex II- General retention periods
        <br />
        <span class="number">10.0</span> Useful information if you are a{" "}
        <span class="annotation">PROSPECT</span>:<br />
        <span class="number">10.1</span> What are the data processing purposes
        and the legitimate basis of the processing?
        <br />
        <span class="number">10.2</span> What kind of data do we hold about you
        and how is your personal data collected?
        <br />
        <span class="number">10.3</span> How long will we keep your data?
        <br />
        ● Annex II- General retention periods
        <br />
        <span class="number">11.0</span> Useful information if you are a{" "}
        <span class="annotation">WEBSITE VISITOR</span>
        <br />
        <span class="number">11.1</span> What are the data processing purposes
        and the legitimate basis of the processing?
        <br />
        <span class="number">11.2</span> What kind of data do we hold about you
        and how is your personal data collected?
        <br />
        <span class="number">11.3</span>How long will we keep your data?
        <br />
        ● Annex II- General retention periods
        <br />
        <span class="number">12.0</span> Do we proceed to international transfer
        of data?
        <br />
        <span class="number">13.0</span> Do we proceed to automated decision
        making?
        <br />
        <span class="number">14.0</span> Do we proceed to profile creations?
        <br />
        <span class="number">15.0</span> What security measures have been
        adopted?
        <br />
        <span class="number">16.0</span> Definitions
        <br />
        Annex I - Details of Orderfood tech Groups companies
        <br />
      </p>
      <h2>1. Scope</h2>
      <h3>Our commitment</h3>
      <p>
        Orderfood tech is committed to respecting Users’/ Couriers’/ Job
        Applicants’/ Partners’/ Investors’/ Prospects’/ Website Visitors’
        (jointly named hereinafter as the “Data Subjects”) privacy and
        protecting their Personal data. In this sense, the aim of this Privacy
        Policy is to inform Data Subjects about Orderfood’s tech processing
        activities as per GDPR and the Local Regulation requirements.
      </p>
      <h3>The purpose of the Privacy Policy</h3>
      <p>
        This Privacy Policy provides information on the processing of personal
        data of Data Subjects who contact us via the contact form available on
        our website, in accordance with the General Data Protection Regulation
        ("GDPR"). To find them, the different stakeholders involved in this
        processing as well as our Data Subjects can enter our main website{" "}
        <span class="tags">
          orderfood.ng{">>"}Contact us{">>"}Policies
        </span>{" "}
        or App:
        <span class="tags">
          Help{">>"}Not related to an order{">>"}Policies
        </span>{" "}
        and there they can select the right which they wish to exercise. This
        Privacy Policy notice describes: how Orderfood tech collects and
        processes the personal data, the different stakeholders involved in this
        processing as well as our Data Subjects rights with regard to their
        data. We recommend that both all stakeholders involved in the processing
        as well as Data Subjects read it carefully and on a frequent basis to
        fully understand this along with our privacy overview, which highlights
        key points about our current privacy practices.
      </p>
      <h3>Any doubts? Contact our DPO</h3>
      <p>
        If our Data Subjects have any doubt concerning this Privacy Policy or
        want to obtain more information on it, they can contact our DPO at any
        time to this email address:{" "}
        <a href="gdpr@orderfood.ng" class="link">
          gdpr@orderfood.ng
        </a>
      </p>
      <h2>2. Summary of information</h2>

      <ul class="table">
        <li>Data Controller</li>
        <li>● [Add local company details]</li>
        <li>Your rights</li>
        <li>
          You can exercise your rights related to personal data at any time by
          sending us an email to: gdpr@orderfood.ng Your rights related to
          personal data: right of access, right to rectification, right to
          erasure, right to object to processing, right to object to profiling,
          right to unsubscribe to any marketing communication
        </li>
        <li>
          <p>Data Protection Officer</p>
        </li>
        <li>
          <p>gdpr@orderfood.ng</p>
        </li>
        <li>
          <p>For which purpose do we use your data?</p>
        </li>
        <li>
          <p>
            When you are a <span class="annotation">User</span> or{" "}
            <span class="annotation">Courier</span> we may use your data for:
          </p>
          <p>
            ● legal purposes (e.g. in case of frauds and crimes committed on our
            App; to comply with legal regulations; menage your
          </p>
          <p>
            ● contractual purposes (e.g. grant the possibility to create your
            own account, locate the nearest Partner and Courier, perform payment
            processing, assist in decisions and use of the service, provide you
            with a customer service, studying the use of the platform performed
            by you, provide you with the equipment
          </p>
          <p>
            ● security purposes (e.g. use device, location, profile, usage, and
            other data to prevent and detect malicious or unsafe activities,
            monitor all actions that could cause fraud, when requested by any
            authority, administration or court, collaborate with Public
          </p>
          <p>
            ● statistics and research purposes (e.g. analyse trends, purchase
            behaviour and characteristics, understand how you use our Platform,
            send voluntary and anonymous questionnaires, and surveys,
            competitions and communications of your interest, carry out
            financial calculations),
          </p>
          <p>
            ● marketing and non marketing purposes (e.g. carry out marketing,
            communications, research and development activities, analyse how to
            improve our services, provide you with offers, promotions,
            discounts, suggestions, carry out promotional activities for the
            delivery of samples or free products, generate and provide you with
            receipts, inform you about any incident on the App). When you are a
            Partner we may use your data for:
          </p>
          <p>
            ● grant you the possibility to create your own account and maintain
            the business relationship with orderfood tech,
          </p>
          <p>
            ● provide you with customer service to answer your questions or
            consultations. When you are an{" "}
            <span class="annotation">Investor</span> we may use your data for:
          </p>
          <p>● fulfil the compliance requirements</p>
          <p>● formalise the relationship with you as an Investor,</p>
          <p>
            ● notify you concerning the corporate matters and inform you about
            Orderfood tech results. When you are a{" "}
            <span class="annotation">Job Applicant</span> we may use your data
            for:
          </p>
          <p>
            ● consider your present or future suitability for any of the
            positions available at Orderfood, to conduct any interviews it may
            deem necessary for the position, test your knowledge, contact
            companies for which you have previously worked, check references,
            and assess your skills and abilities in general.
          </p>
          <p>
            ● provide you with information of your interest regarding our Job
            offers. When you are a <span class="annotation">Prospect</span> we
            may use your data for:
          </p>
          <p>
            ● process your requests to obtain the products and/or services
            offered by Orderfood and manage the prospective relationship,
          </p>
          <p>
            ● carry out marketing, communications, research activities regarding
            Orderfood products and/or services similar to those included in your
            request. When you are a <span class="annotation">Web Visitors</span>{" "}
            we may use your data for:
          </p>
          <p>
            ● follow up your comments left on Orderfood Website or our Blog,
          </p>
          <p>● provide you with the answer regarding your</p>
        </li>
        <li>
          <p>Why can we do it?</p>
          <p>Lawfulness of processing</p>
        </li>
        <li>
          <p>● Compliance with the business/ labour relationship.</p>
          <p>
            ● Legitimate interest and User/ Courier / Applicant/ Investor/
            Website visitor consent.
          </p>
        </li>
        <li>With whom we share your data?</li>
        <li>
          <p>
            If you are a <span class="annotation">User</span> we may share your
            data with two different group of recipients:
          </p>

          <p>
            1) The recipients of data while we carry on your orders: the{" "}
            <span class="annotation">Courier</span> who carries out the task of
            collecting and delivering the product; the establishment or venue in
            charge of selling the product; the Customer Care Services available
            to you; the payment Platform and payment service providers;
            Telecommunications service providers; Providers rendering
            satisfaction survey services
          </p>
          <p>
            2) Recipients of the data, during the actions undertaken to continue
            to provide the services offered through the App: service providers
            that send parcels, carry out orders and/or resolve incidents with
            delivery; pharmacists dispensing products; Payment Platforms
            contracted by Orderfood; Service providers for fraud control
            purposes and for for the anonymisation of some data;
            Telecommunications services; Punctually another entity or affiliate
            of the Orderfood Group; Social media, Third parties associated with
            Orderfood for the purposes of commercial communications; insurance
            companies If you are a Courier we may share your data with different
            group of recipients:
          </p>
          <p>
            ● advisers and consultants for the pursuit of accounting, labour,
            tax, insurance, legal and technical activities,
          </p>
          <p>
            ● government bodies (tax authority, social security system, etc.),
          </p>
          <p>
            ● state security forces, courts, mediation and arbitration bodies,
            governments for regulatory matters ● users who have placed an order
            which you have agreed to carry out,
          </p>
          <p>
            ● external service providers that provide commercial offers with
            benefits and/or discounts for you,
          </p>
          <p>
            ● to group companies or third parties in charge of the filing and
            management of computer data. In case of{" "}
            <span class="annotation">
              every Data Subject (Users, Couriers, Job Applicants
            </span>
            <span class="annotation">
              Partners, Investors, Prospects, Website Visitors)
            </span>{" "}
            we may share personal data with:
          </p>

          <p>● another entity or affiliate of the Orderfood Tech,</p>
          <p>● any public authority that requests the sharing.</p>
        </li>
      </ul>
    </div>
  );
};

export default Policy;
