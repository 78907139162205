import axios from "axios";

const apiURL = "https://app.orderfood.ng/api/v1";

export const fetchTopRes = () => {
  return axios.get(`${apiURL}/landing/top-rated`);
};

export const fetchCat = () => {
  return axios.get(`${apiURL}/landing/categories`);
};

export const fetchResByCat = (id) => {
  return axios.get(
    `${apiURL}/landing/store-products-by-category/${id}?limit=8&offset=1`
  );
};
